import Choices from 'choices.js';

(function ($) {
    "use strict";

    const $window = $(window);
    const $body = $('body');

    /* Header Sticky */
    function headerSticky() {
        if ($window.scrollTop() > 350) {
            $('.sticky-header').addClass('is-sticky');
        } else {
            $('.sticky-header').removeClass('is-sticky');
        }
    }

    /* Mobile Sub Menu Toggle Function */
    const $mobileSubMenuToggle = $('.mobile-sub-menu-toggle')
    $mobileSubMenuToggle.on('click', function() {
        const $this = $(this),
            $mobileSubMenuToggleClass = '.mobile-sub-menu-toggle',
            $mobileSubMenuClass = '.mobile-sub-menu';
        if($this.hasClass('active')) {
            $this.removeClass('active').closest('li').removeClass('active').find($mobileSubMenuToggleClass).removeClass('active').closest('li').removeClass('active').find($mobileSubMenuClass).slideUp()
        } else {
            $this.addClass('active').siblings($mobileSubMenuClass).slideDown()
            $this.closest('li').addClass('active').siblings().find($mobileSubMenuToggleClass).removeClass('active').closest('li').removeClass('active').find($mobileSubMenuClass).slideUp()
        }
    })


    $('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Učitavanje #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1]
        },
        image: {
            tError: '<a href="%url%">Sliku #%curr%</a> nije moguce ucitati.',
        }
    });


    $('.select2box').select2({
        width: '100%',
        allowClear: true,
        placeholder:'Odaberite...'
    });

    /* Scroll To Top */
    const scrollToTopBtn = $('.scroll-to-top');
    scrollToTopBtn.on('click', function() {
        $("html, body").animate({ scrollTop: 0 });
        return false;
    })
    function scrollToTopShow() {
        if ($window.scrollTop() > $window[0].outerHeight) {
            scrollToTopBtn.addClass('show');
        } else {
            scrollToTopBtn.removeClass('show');
        }
    }

    /* Resize Event */
    $window.on('resize', function() {
        //imageZoom()
    })
    /* Scroll Event */
    $window.on('scroll', function () {
        headerSticky()
        scrollToTopShow()
    });
})(jQuery);