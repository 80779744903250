import Swiper from 'swiper/bundle';


/* Swiper Slider */
/* Hero Slider */
const mainSlider = new Swiper('.extreme-slider', {
    loop: true,
    effect: 'fade',
    autoplay: {
        delay: 4000,
    },
    fadeEffect: {
        crossFade: true
    },
    pagination: {
        el: '.extreme-slider .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.extreme-slider .swiper-button-next',
        prevEl: '.extreme-slider .swiper-button-prev',
    }
});

/* Single Product Thumbnail Carousel */
const productThumbCarousel = new Swiper('.product-thumb-carousel', {
    slidesPerView: 4,
    spaceBetween: 10,
    pagination: {
        el: '.product-thumb-carousel .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.product-thumb-carousel .swiper-button-next',
        prevEl: '.product-thumb-carousel .swiper-button-prev',
    }
});

/* Single Product Image Slider */
const productImageSlider = new Swiper('.product-image-slider', {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
        el: '.product-image-slider .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.product-image-slider .swiper-button-next',
        prevEl: '.product-image-slider .swiper-button-prev',
    },
    thumbs: {
        swiper: productThumbCarousel,
    },
});
/* Product Carousel */
const productCarousel = new Swiper('.product-carousel', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 1,
    centeredSlides: true,
    watchSlidesProgress: true,
    pagination: {
        el: '.product-carousel .swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.product-carousel .swiper-button-next',
        prevEl: '.product-carousel .swiper-button-prev',
    },
    breakpoints: {
        576: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 2
        },
        992: {
            slidesPerView: 3,
            spaceBetween: 2
        },
        1200: {
            slidesPerView: 5,
            spaceBetween: 2
        }
    }
});