

(function ($) {
    "use strict";

    const $window = $(window);
    const $body = $('body');

    /* Product Quantity */
    $('.product-quantity-count').on('click', '.qty-btn', function(e) {
        e.preventDefault()
        const $btn = $(this),
            $box = $btn.siblings('.product-quantity-box')[0];
        if($btn.hasClass('inc')) {
            $box.value = Number($box.value) + 1
        } else if($btn.hasClass('dec') && Number($box.value) > 1) {
            $box.value = Number($box.value) - 1
        }
    });

    /* Image Zoom */
    function imageZoom () {
        const $elem = $('.image-zoom'),
            $zoomImage = $('.image-zoom .zoomImg');
        if($window.outerWidth() < 992) {
            if($zoomImage.length !== 0) {
                $elem.trigger('zoom.destroy');
            }
        } else {
            if($zoomImage.length === 0) {
                $elem.zoom();
            }
        }
    }

    imageZoom();

    /* Resize Event */
    $window.on('resize', function() {
        imageZoom()
    });

    // Cart - Dodaj hidden input za količinu kod updejta količine u košarici
    $(document).on ("submit", ".update-cart-form", function(eventObj) {
        var id = $(this).data('id');

        $('<input />').attr('type', 'hidden')
            .attr('name', "quantity")
            .attr('value', $("#quantity-"+id).val())
            .appendTo($(this));
        return true;
    });
    // Checkout - Tvrtka i OIB hide/show
    $(document).on ("change", "#is_company", function (event) {

        if(this.value == "1") {
            $(".oib-fields").show();
            $(".company-fields").show();
        } else {
            $(".oib-fields").hide();
            $(".company-fields").hide();
        }
    });
    $(document).on ("click", "#is_company_check", function (event) {
        if ($(this).prop("checked")) {
            $(".oib-fields").show();
            $(".company-fields").show();
        } else {
            $(".oib-fields").hide();
            $(".company-fields").hide();
        }
    });
    // Checkout - Prikaži formu sa podacima
    $(document).on ("change", "#address_select_box", function (event) {

        if(this.value == "new") {
            $("#userinfo-container").show();
        } else {
            $("#userinfo-container").hide();
        }

    });

    ////////////////////
    // Product list
    ////////////////////

    // product list - Promjeni url nakon promjene sortiranja
    $(document).on ("change", "#products_sort", function (event) {

        var sort = this.value.split(":");

        var parameters = "order=" + sort[0] + "&direction=" + sort[1];

        var params = parseQueryString();

        var current_parameters = "";

        for(var propt in params) {
            if(propt != "order" && propt != "direction" && propt != "page") current_parameters += "&"+propt+"="+params[propt];
        }

        var redirect_url = document.location.pathname + "?" + parameters + current_parameters;

        document.location = redirect_url;

    });

    // product list - Promjeni url nakon promjene broja proizvoda po stranici
    $(document).on ("change", "#products_per_page", function (event) {

        var per_page = this.value;

        var parameters = "items=" + per_page;

        var params = parseQueryString();

        var current_parameters = "";

        for(var propt in params) {
            if(propt != "items" && propt != "page") current_parameters += "&"+propt+"="+params[propt];
        }

        var redirect_url = document.location.pathname + "?" + parameters + current_parameters;

        document.location = redirect_url;

    });

    // product list - Filteri
    $(document).on ("change", ".filter_attribute, .filter_feature, .filter_manufacturer, .filter_category, .filter_maxprice, .filter_warranty, .filter_condition, .filter_price-from, .filter_price-to", function (event) {

        var url = $( "#filters-form").data('url');

        var filters = $('#filters-form').serialize();

        var params = parseQueryString();

        var current_parameters = "";

        for(var propt in params) {
            if(propt.substring(0, 7) != "feature" && propt.substring(0, 9) != "attribute" && propt.substring(0, 12) != "manufacturer" && propt.substring(0, 8) != "category" && propt.substring(0, 8) != "maxprice" && propt.substring(0, 8) != "warranty" && propt.substring(0, 9) != "condition" && propt.substring(0, 9) != "pricefrom" && propt.substring(0, 7) != "priceto") current_parameters += "&"+propt+"="+params[propt];
        }

        var new_data = filters + current_parameters;

        var new_url = document.location.pathname + "?" + new_data;

        $.ajax({
            type: "GET",
            url: url+'?ajax=true',
            data: new_data,
            success: function(data) {
                updateProductsList(data);
            }

        });

        window.history.pushState({path:new_url},'',new_url);


    });

})(jQuery);

window.updateCart = function(data) {

    if($("#cart-quantity").length) {
        $("#cart-quantity").text(data.quantity);
        $("#cart-quantity").show();
    }

    if($("#cart-total").length) {
        $("#cart-total").text(data.total_with_tax);
        $("#cart-total").show();
    }
    if($("#cart-total-eur").length) {
        $("#cart-total-eur").text(data.total_with_tax_eur);
        $("#cart-total-eur").show();
    }
    if(data.success && $("#cart-menu .cart-menu-items").length) {
        $("#cart-menu .cart-menu-items").html(data.menu);
        $("#cart-menu .cart-menu-total .price").html(data.total_with_tax);
        $("#cart-menu .cart-menu-total-eur .price").html(data.total_with_tax_eur);
        initAjaxForm();
    }
}


window.updateProductsList = function(data) {

    $('#product-list').html(data.items);
    $('#product-list-pagination').html( data.pagination);

    $('#products-count').html( data.items_count);
    $('#products-count-total').html( data.items_count_total);

    $('#product-list').data('next-page', data.next_page);
    $('#product-list').data('current-page', data.current_page);

    $( document ).trigger( "productListUpdated");

    if(data.more_pages) $('.ajax-load-btn').show();
    else $('.ajax-load-btn').hide();

}

var parseQueryString = function() {

    var str = window.location.search;
    var objURL = {};

    str.replace(
        new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
        function( $0, $1, $2, $3 ){
            objURL[ $1 ] = $3;
        }
    );
    return objURL;
};

function number_format (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}