$(document).ready(function() {

    initAjaxForm();

    initAjaxButton();

    // Dodaj csrf token u hedere svih ajax zahtjeva
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

});

var loader_button = '';
var loader_text = '';
// Ajax button
window.initAjaxButton = function() {

    $(document).on ("click", ".ajax-button", function (event) {

        form = $(this);

        var url = $(this).attr("data-href");
        var method = $(this).attr("data-method");

        if(!method) method = "POST";

        if(method == "delete" || method == "DELETE") {
            method = "POST";
            _method = "DELETE";
        } else {
            _method = method;
        }


        $.ajax({
            type: method,
            data: {
                _method: _method
            },
            url: url,
            success: function(data, status, xhr) {
                ajaxSuccess( data, status, xhr, form );
            }

        });

        event.preventDefault();
    });

}

// Ajax forme
window.initAjaxForm = function() {

    $('.ajax-form').ajaxForm({
        dataType:  'json',
        beforeSubmit: function(arr, form, options) {

            ajaxBeforeSubmit(arr, form, options);

        },
        success : function ( data, status, xhr, form ) {

            ajaxSuccess( data, status, xhr, form );

        },
        error : function ( data, status, xhr, form ) {

            ajaxError( data, status, xhr, form );

        },
    });

}

window.ajaxBeforeSubmit = function(arr, form, options) {

    // Pokreni funkciju
    if(form.attr('data-before-run')) window[form.data('before-run')](arr, form, options);
    // Postavi loader na button
    if(!form.attr('data-loader-false')) {

        loader_button = form.data('loader');
        loader_text = form.find(loader_button).html();
        if (typeof loader_text === 'undefined') {
            loader_button = 'button[type="submit"]';
            loader_text = form.find(loader_button).html();
        }

        form.find(loader_button).css("width", form.find(loader_button).outerWidth() + 'px');

        if(form.attr('data-loader-text')) var loading_text = form.data('loader-text');
        else var loading_text = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-spin" role="img" aria-hidden="true" style="/*! font-size: 40px; */"><path d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>';

        form.find(loader_button).html(loading_text);
    }

}

window.ajaxSuccess = function( data, status, xhr, form ) {
    // Pokreni funkciju ako je success
    if(data.success && form && form.attr('data-success-run')) window[form.data('success-run')]( data, status, xhr, form );
    // Pokreni funkciju ako je success 2
    if(data.success && form && form.attr('data-success-run2')) window[form.data('success-run2')]( data, status, xhr, form );
    // Pokreni funkciju ako je success 3
    if(data.success && form && form.attr('data-success-run3')) window[form.data('success-run3')]( data, status, xhr, form );
     // Pokreni funkciju ako je success 4
    if(data.success && form && form.attr('data-success-run4')) window[form.data('success-run4')]( data, status, xhr, form );

    // Reload ako je uspješno
    if(data.success && form && form.attr('data-success-reload')) reloadPage();
    // Modal toggle
    if(data.success && form && form.attr('data-modal-toggle')) $(form.data('modal-toggle')).modal('toggle');
    // Obriš vrijednosti forme
    if(data.success && form && form.attr('data-clear-form')) clearForm(data,form);

    // Makni loader sa buttona
    if(typeof loader_button !== 'undefined' && form && !form.attr('data-loader-false')) form.find(loader_button).html(loader_text);
    // Refresh
    if(data.refresh) {
        location.reload(true);
        return;
    }
    //Redirect
    if(data.redirect) {
        location.replace(data.redirect);
        return;
    }

    if(data.success && form && form.attr('data-success-run-after')) window[form.data('success-run-after')]( data, status, xhr, form );

    if(form && form.attr('data-sweet')) data.sweet = true;

    if(form && form.attr('data-no-sweet')) data.sweet = false;

    if(form && form.attr('data-no-alert')) return 1;

    // Prikazi obavijesti
    if(data.sweet) showSweetalert(data);
    else showNotification(data);

}

window.ajaxError = function( data, status, xhr, form ) {

    // Pokreni funkciju ako je success
    if(form.attr('data-error-run')) window[form.data('error-run')]( data, status, xhr, form );
    // Makni loader sa buttona
    if(form.attr('data-loader')) form.find(loader_button).html(loader_text);
    else if(typeof loader_button !== 'undefined' && !form.attr('data-loader-false')) form.find(loader_button).html(loader_text);

    var msg = {error:"Došlo je do greške: " + xhr};
    //alert(JSON.stringify(data));
    showNotification(msg);

}



// Učitaj sadržaj preko ajaxa - json
window.loadData = function(page, target) {
    $.ajax({
        method : 'POST',
        url : page,
        dataType: 'json',
    }).done(function (data) {
        $(target).html(data);
        //location.hash = page;
    }).fail(function () {
        alert('data could not be loaded.');
    });
}

// Očisti inpute forme
window.clearForm = function(data,form) {

    form.find("input[type=text], textarea").val("");

}

// Refreshaj stranicu
window.reloadPage = function() {

    location.reload(true);
    return;

}
