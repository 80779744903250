
import {MeiliSearch} from 'meilisearch'

const client = new MeiliSearch({
    host: window.search_domain,
    apiKey: window.search_key,
})


const search_input = document.querySelector("#searchbox-input");
const search_results = document.querySelector("#search-results-container");
const search_clear = document.querySelector("#clear-search-button");

const products = client.index('products');
const articles = client.index('articles');
const product_categories = client.index('product_categories');


var results_hovered = false;


initExtremeSearch(search_input, search_results, search_clear);


function initExtremeSearch(input, results, clear) {

    const results_products = results.querySelector(".search-results-products");
    const results_articles = results.querySelector(".search-results-articles");
    const results_categories = results.querySelector(".search-results-categories");

    function clearResults() {
        results.style.display = 'none';
        results_products.innerHTML = '';
        results_articles.innerHTML = '';
        results_categories.innerHTML = '';
        clear.style.display = 'none';
        input.value = '';
    }

    input.addEventListener('keyup', event => {
        let query = event.target.value;
        if(query == "") {
            clearResults();
            return false;
        }

        results.style.display = 'block';
        clear.style.display = 'block';

        products.search(query, {
            limit: 8,
            attributesToHighlight: ['name']
        })
        .then(response => {
            let items = response.hits.map(item => {
                let div = document.createElement('div');
                div.className = 'search-result-item';
                div.innerHTML = `<a href="${item.link}">
                                            <div class="thumb-picture">
                                                <img src="/imager/50x50${item.image}">
                                            </div> 
                                            <div class="info-box">
                                                <span>${item._formatted.name}</span>
                                            </div> 
                                    </a>`;
                return div;
            });

            results_products.innerHTML = '';
            if(items.length) {
                results.querySelector(".search-empty-products").style.display = 'none';
                results_products.append(...items);    
            }
            else {
                results.querySelector(".search-empty-products").style.display = 'block';
            }
            
        });

        articles.search(query, {
            limit: 3,
            attributesToHighlight: ["title"]
        })
        .then(response => {
            let items = response.hits.map(item => {

                let div = document.createElement('div');
                div.className = 'search-result-item';
                div.innerHTML = `<a href="${item.link}"><span>${item._formatted.title}</span></a>`;
                return div;
            });

            results_articles.innerHTML = '';
            if(items.length) {
                results.querySelector(".search-empty-articles").style.display = 'none';
                results_articles.append(...items);    
            }
            else {
                results.querySelector(".search-empty-articles").style.display = 'block';
            }
        });

        product_categories.search(query, {
            limit: 3,
            attributesToHighlight: ["name"]
        })
        .then(response => {
            let items = response.hits.map(item => {

                let div = document.createElement('div');
                div.className = 'search-result-item';
                div.innerHTML = `<a href="${item.link}"><span>${item._formatted.name}</span></a>`;
                return div;
            });

            results_categories.innerHTML = '';
            if(items.length) {
                results.querySelector(".search-empty-categories").style.display = 'none';
                results_categories.append(...items);    
            }
            else {
                results.querySelector(".search-empty-categories").style.display = 'block';
            }
        });
    });


    input.addEventListener('blur', event => {

        if(!results_hovered) {
            //results.style.display = 'none';
        }

    });

    clear.addEventListener('click', event => {
        clearResults()

    });

    results.addEventListener("mouseover", function( event ) {
        results_hovered = true
    });

    results.addEventListener("mouseout", function( event ) {
        results_hovered = false
    });

}


